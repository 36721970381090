<template>
<CourierCompanyBase :loader_prop="loader_prop">
 delete courier
 </CourierCompanyBase >
</template>

<script>
import BackendModel from "../../models/BackendModel";
import CourierCompanyBase from "./CourierCompanyBase";
import('./couriercompany/css/style.css')
export default {
  name: 'CourierCompanyDeleteCourier',
  components: {
    CourierCompanyBase
  },
  data() {
    return {
   courier_id :'',
   loader_prop: true
    }
  },
   async mounted () {
    let backendModel = new BackendModel()
        let courier_id = this.$route.query.id
        await backendModel.courierCompany_Request("/Api/service/backoffice/courier_company_delete_courier", {courier_id})
        await this.$router.push({ path: '/courierCompany/courier_company_courier' }) 
        this.loader_prop =false
   },
 
}
</script>

<style>
@import 'couriercompany/css/style.css';
</style>
